define(['app'], function(app) {

  var productPrice = function() {
    var component = {};

    var _config = {
      selectors: {
        component: '[data-component=productPrice]',
        rrp: '[data-product-price=rrp]',
        price: '[data-product-price=price]',
        saving: '[data-product-price=saving]',
        schemaPrice: '[data-schema=price]',
        recurringPayment: '[data-product-price=recurringPayment]',
        fromPrice: '[data-product-price="fromPrice"]',
        professionalPricingBanner: '[data-product-price=professionalPricingBanner]',
        optionTerm: '[data-product-price=paymentOptionTerm]',
        monthSingular: '[data-product-price=optionMonthTextSingular]',
        monthPlural: '[data-product-price=optionMonthTextPlural]',
        recurringPaymentText: '[data-product-price=recurringPaymentText]',
        fastTrackSwitch: '.fastTrack_onProductPage',
        OTPMessage: '.productPrice_subscribeAndSave_oneTimePurchase_message',
        subscribeMessage: '.productPrice_subscribeAndSave_subscribe_message',
        subscribeAndSaveMessagesWrapper: '.productPrice_subscribeAndSave_message',
        upsellMessage: '.productPrice_subscribeAndSave_subscribe_upsellMessage',
        personalisableProduct: '[data-component=personalisationSelect]',
        withEngravingTab: '.personalisation_withEngraving_tab',
      },
      channels: {
        price: 'productPrice/newPrice',
        subscribeAndSaveContractsContractIdChange: 'subscribeAndSaveContracts/changeContractId',
        upsellMessage: 'productPrice/upsellMessage',
      },
      attributes: {
        subscribeAndSave: 'data-subscribe-and-save',
        productId: 'data-product-id',
        subscriptionContractId: 'data-contract-id',
        personalisationSelectedTab: 'data-selected',
      }
    };

    var _init = function (element, userDriven) {
      component.element = element;
      component.productId = element.getAttribute(component.config.attributes.productId);

      if (!userDriven) {
        component.subscribeToChannels();
      } else {
        // set the correct state of the messages after the price component has been reinitialised
        component.changeSubscribeAndSaveMessage(component.isSubsTab);
      }

      return component;
    };

    var _getNewPrice = function(productId, subscriptionContractId, isSubsTab) {
      let fastTrack = document.querySelector(component.config.selectors.fastTrackSwitch);
      let url = '/' + productId + '.price';
      const queryParams = [];

      if (fastTrack) {
        queryParams.push('useCookieForFastTrackFiltration=' + fastTrack.getAttribute('data-switch-enabled'));
      }

      let enableSubscribeAndSave = component.element.getAttribute(component.config.attributes.subscribeAndSave);
      if (enableSubscribeAndSave) {
        queryParams.push('enableSubscribeAndSave=' + enableSubscribeAndSave);
      }

      if (subscriptionContractId) {
        queryParams.push('subscriptionContractId=' + subscriptionContractId);
      }

      if (typeof isSubsTab !== 'undefined' ) {
        queryParams.push('isSubscriptionTab=' + isSubsTab);
        component.isSubsTab = isSubsTab;
      }
      
      if (component.hasProductPersonalisation() && !component.isPersonalisationMandatory()) {
        const withEngravingTab = document.querySelector(component.config.selectors.withEngravingTab);
        if (withEngravingTab) {
          const isTabSelected = withEngravingTab.getAttribute(component.config.attributes.personalisationSelectedTab);
          const selectedTabWithEngraving = isTabSelected === 'true';
          queryParams.push('usePersonalisationPricing=' + selectedTabWithEngraving);
        }
      }

      const queryString = queryParams.join('&');
      if (queryString) {
        url += '?' + queryString;
      }

      app.ajax.get({
        url: url,
        success: component.successHandler,
        error: component.errorHandler
      });
    };

    var _successHandler = function(response) {
      let parent = component.element.parentNode;
      parent.innerHTML = response;
      let element = parent.querySelector('[data-component=productPrice]');
      component.init(element, true);
    };

    var _errorHandler = function() {
      console.error(
        'ERROR: Could not retrieve new price options');
    };

    const _changeSubscribeAndSaveMessage = (isSubTab) => {
      let OTPMessage = component.element.querySelector(component.config.selectors.OTPMessage);
      let subscribeMessage = component.element.querySelector(component.config.selectors.subscribeMessage);

      OTPMessage && OTPMessage.setAttribute('data-visible', !isSubTab);
      subscribeMessage && subscribeMessage.setAttribute('data-visible', isSubTab);

      if(subscribeMessage) {
        let upsellMessage = component.element.querySelector(component.config.selectors.upsellMessage);
        upsellMessage && app.publish(component.config.channels.upsellMessage, upsellMessage.innerHTML);
      } else {
        app.publish(component.config.channels.upsellMessage);
      }
    };

    const _updatePriceFromProductIdChange = (productId) => {
      let subscribeAndSaveMessagesWrapper =
        component.element.querySelector(component.config.selectors.subscribeAndSaveMessagesWrapper);

      let subscriptionContractId = null;
      if (subscribeAndSaveMessagesWrapper) {
        subscriptionContractId =
          subscribeAndSaveMessagesWrapper.getAttribute(component.config.attributes.subscriptionContractId);
      }

      if (component.productId !== productId) {
        component.productId = productId;
      }

      component.getNewPrice(productId, subscriptionContractId);
    };

    const _updatePriceFromContractIdChange = (contractId, isSubsTab) => {
      component.getNewPrice(component.productId, contractId, isSubsTab);
    };

    const _subscribeToChannels = () => {
      app.clear(component.config.channels.subscribeAndSaveContractsContractIdChange);
      app.subscribe(component.config.channels.price, component.updatePriceFromProductIdChange);
      app.subscribe(component.config.channels.subscribeAndSaveContractsContractIdChange, component.updatePriceFromContractIdChange);
    };

    const _hasProductPersonalisation = () => {
      return !!document.querySelector(component.config.selectors.personalisableProduct);
    }
    const _isPersonalisationMandatory = () => {
      const mainDiv = document.querySelector('#mainContent'); 
      const persRequired = mainDiv.hasAttribute('data-mandatory-personalisation');
      return persRequired;
    }

    component.init = _init;
    component.config = _config;
    component.getNewPrice = _getNewPrice;
    component.successHandler = _successHandler;
    component.errorHandler = _errorHandler;
    component.changeSubscribeAndSaveMessage = _changeSubscribeAndSaveMessage;
    component.subscribeToChannels = _subscribeToChannels;
    component.updatePriceFromProductIdChange = _updatePriceFromProductIdChange;
    component.updatePriceFromContractIdChange = _updatePriceFromContractIdChange;
    component.hasProductPersonalisation = _hasProductPersonalisation;
    component.isPersonalisationMandatory = _isPersonalisationMandatory;

    return component;
  };

  return productPrice;
});
